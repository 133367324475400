import common from '@/mixin/common.js';
import {
	BaiduMap,
	BmView,
	BmMarker,
	BmNavigation,
	BmScale,
	BmGeolocation,
	BmMapType,
	BmPanorama
} from 'vue-baidu-map';

export default {
	name: 'r-contact',
	mixins: [common],
	data() {
		return {
			mapBgImage: require('@/assets/join/bg.jpg'),
			addressInfo: [
				// {
				// 	image: require('@/assets/contact/bg_beijing.png'),
				// 	location: {
				// 		longtitude: 116.38112,
				// 		latitude: 39.987978
				// 	},
				// 	select: false,
				// },
				// {
				// 	image: require('@/assets/contact/bg_nanjing.png'),
				// 	location: {
				// 		longtitude: 118.76399,
				// 		latitude: 31.97831
				// 	},
				// 	select: false,
				// },
				{
					image: require('@/assets/contact/bg_ruishi.png'),
					// address: require('@/assets/contact/map_ruishi.png'),
					// addressMobile: require('@/assets/contact/m_map_ruishi.png'),
					location: {
						longtitude: 8.559522,
						latitude: 47.415336
					},
					select: true,
				}
			],
			// footInfo: [
			// 	{
			// 		detail: [
			// 			{
			// 				image: require('@/assets/contact/ic_email_blue.png'),
			// 				type: 'mailto:alpsentek@alpsentek.com',
			// 				title: 'alpsentek@alpsentek.com'
			// 			},{
			// 				image: require('@/assets/contact/ic_phone_blue.png'),
			// 				type: 'tel:+8675526911746',
			// 				title: '中国：+86 75526911746 <br /> 瑞士：+41 43 299 6970'
			// 			}
			// 		]
			// 	},{
			// 		detail: [
			// 			{
			// 				image: require('@/assets/contact/ic_email_blue.png'),
			// 				type: 'mailto:talent@alpsentek.com',
			// 				title: 'talent@alpsentek.com'
			// 			}
			// 		]
			// 	},{
			// 		detail: [
			// 			{
			// 				image: require('@/assets/contact/ic_in_blue.png'),
			// 				type: 'https://www.linkedin.com/company/31467119/',
			// 				title: 'www.linkedin.com/company/31467119/'
			// 			}
			// 		]
			// 	}
			// ]
		}
	},
	mounted() {
		// this.scrollToTop();
	},
	methods: {
		changeAddress(params) {
			this.addressInfo.map(item => {
				item.select = false;
			});
			params.select = true;
		},
		getLocationPoint(params) {
			console.log(' ===  getLocationPoint ');
		},
		mapHandler({
			BMap,
			map
		}) {
			this.BMap = BMap;
			this.map = map;

			// this.BMap.Point(116.421289, 40.04592);
			// const map = new BMap.Map("Map");
			// const point = new BMap.Point(116.404, 39.915);
			// map.centerAndZoom(point, 11);
			// map.setCurrentCity("北京");
			// const deviceSize = new BMap.Size(48,48);
			// const deviceIcon = new BMap.Icon(iconCar, deviceSize, { //会以base64的方式传参iconCar
			// 		imageSize: deviceSize
			// });

			// const marker = new BMap.Marker(point, {
			// 		icon: deviceIcon
			// });
		},
	},
	components: {
		BaiduMap,
		BmView,
		BmMarker,
		BmNavigation,
		BmScale,
		BmGeolocation,
		BmMapType,
		BmPanorama,
	},
	computed: {
		curLocation() {
			const params = this.addressInfo.find(item => item.select);
			if (params && params.location) {
				return {
					lng: params.location.longtitude,
					lat: params.location.latitude
				};
			} else {
				return !this.isMobileBrowse ? params.address : params.addressMobile;
			}
		},
		footInfo() {
			var self = this
			return [{
				detail: [{
					image: require('@/assets/contact/ic_email_blue.png'),
					type: 'mailto:alpsentek@alpsentek.com',
					title: 'business@alpsentek.com'
				}, 
				// {
				// 	image: require('@/assets/contact/ic_phone_blue.png'),
				// 	type: 'tel:+8675526911746',
				// 	title: self.zhcn ? '中国：+86 75526911746 <br /> 瑞士：+41 43 299 6970' :
				// 		"China：+86 75526911746 <br /> Switzerland：+41 43 299 6970"
				// },
				{
					image: require('@/assets/contact/ic_phone_blue.png'),
					type: 'tel:+8675526911746',
					title: self.zhcn ? '中国：+86 75526911746 <br /> 瑞士：+41 43 299 6970' :
						"Switzerland：+41 43 299 6970"
				},
				]
			}, {
				detail: [{
					image: require('@/assets/contact/ic_email_blue.png'),
					type: 'mailto:talent@alpsentek.com',
					title: 'talent@alpsentek.com'
				}]
			}, {
				detail: [{
					image: require('@/assets/contact/ic_in_blue.png'),
					type: 'https://www.linkedin.com/company/31467119/',
					// title: 'www.linkedin.com/company/31467119/'
					title: ""
				}]
			}]
		}
	}
}
